<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mb-2"
      @click="gotoAddPartner()"
    >
      Add Partner
    </b-button>
    <b-row>
      <b-col
        v-if="active_role == 'corporate'"
        col="12"
      >
        <partner-corporate />
      </b-col>
      <b-col
        v-else-if="active_role == 'superadmin'"
        col="12"
      >
        <partner-superadmin />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import PartnerCorporate from './table/PartnerCorporate.vue'
import PartnerSuperadmin from './table/PartnerSuperadmin.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    PartnerCorporate,
    PartnerSuperadmin,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      active_role: null,
    }
  },
  created() {
    this.active_role = localStorage.getItem('role')
    this.checkAuth()
  },
  methods: {
    gotoAddPartner() {
      this.$router.push({ path: '/partner/add' })
    },
  },
}
</script>
